<template>
  <div>
    <v-tooltip bottom>
      <template
        v-slot:activator="{ on, attrs }"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <v-icon
          v-bind:class="`${revisionStatus.class}--text mr-2 icon__size-50`"
          v-on="on"
          v-bind="attrs"
        >
          {{ revisionStatus.icon }}
        </v-icon>
      </template>
      <span>{{ `${$t("tooltip.revision-is-" + revisionStatus.status)}` }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  mdiCheckboxBlankOutline,
  mdiEmailEditOutline,
  mdiLockClock,
  mdiSendCheck,
  mdiTextBoxRemoveOutline,
} from "@mdi/js";
import { mapState } from "vuex";
export default {
  name: "header-right",
  data: () => ({
    iconLock: mdiLockClock,
    iconEdit: mdiEmailEditOutline,
    iconSent: mdiSendCheck,
    iconBlank: mdiCheckboxBlankOutline,
    iconTerminated: mdiTextBoxRemoveOutline,
  }),
  props: {
    singleRevision: {
      type: [Object, Array],
    },
  },
  computed: {
    ...mapState("user", ["currentUser"]),
    currentUserType() {
      if (!this.currentUser) return undefined;
      return this.currentUser.usertype_elemen;
    },

    revisionStatus() {
      let result = { status: "unknown", class: "grey", icon: this.iconBlank };

      if (!this.singleRevision || !this.currentUserType) return result;

      let status = this.singleRevision.memo.status_aksi;
      if (parseInt(this.singleRevision.memo.memo_status_id) === 100) status = 'sent'

      switch (status) {
        case "submitted":
        case "done":
          result = {
            status: "submitted",
            class: "success",
            icon: this.iconSent,
          };
          break;
        case "pending":
          result = { status: "pending", class: "warning", icon: this.iconEdit };
          break;
        case "locked":
          result = { status: "locked", class: "error", icon: this.iconLock };
          break;
        case "terminated":
          result = {
            status: "terminated",
            class: "error",
            icon: this.iconTerminated,
          };
          break;
        case 'sent':
        result = {
            status: "memo-sent",
            class: "success",
            icon: this.iconSent,
          };
          break;
        default:
          break;
      }

      return result;
    },
  },
};
</script>
